import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Routes } from "../helpers/routes";
import Packout from "./Packout";

interface PageRouterProps {
  site: any;
  baseUrl: string;
  cultureCode: string;
}

const PageRouter: React.FC<PageRouterProps> = (props) => {
  const { site, baseUrl, cultureCode } = props;

  return (
    <Switch>
      <Route exact path={`/${baseUrl}/`}>
        <Packout layout="landing" cultureCode={cultureCode} />
      </Route>

      <Route exact path={`/${baseUrl}/${Routes.StackBuilder}/`}>
        <Packout layout="stack-builder" cultureCode={cultureCode} />
      </Route>

      <Route exact path={`/${baseUrl}/${Routes.WallStorageBuilder}/`}>
        <Packout layout="wall-storage-builder" cultureCode={cultureCode} />
      </Route>

      <Route exact path={`/${baseUrl}/${Routes.GetInspired}/`}>
        <Packout layout="get-inspired" cultureCode={cultureCode} />
      </Route>

      {site.landingPage ? (
        <Redirect to={`/${baseUrl}/`} />
      ) : (
        <Redirect to={`/${baseUrl}/${Routes.StackBuilder}/`} />
      )}
    </Switch>
  );
};

export default PageRouter;
